export default function () {
	const { api, apiVersionHeader } = window.Bark;
	const { bugsnagClient } = window;

	const getProjectListForBuyer = (queryParams, success, failure) => {
		const { perPage = 10, nextPage = null } = queryParams;

		let path = nextPage ?? 'buyer/projects/';

		return api(
			path,
			{ perPage },
			(data) => {
				typeof success === 'function' && success(data);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, 'Failed to get projects for the buyer');
				}
			},
			'GET',
		);
	};

	const getNextBarkSuggestions = (success, failure) => {
		api(
			'buyer/next-bark/',
			{},
			(data) => {
				success(data);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, 'Failed to get next bark suggestions');
				}
			},
		);
	};

	const getSellerListForProject = (projectId, options, success, failure) => {
		let { pathname, search, bpvid, filter, version, sort, pageNum } = options;
		let psidh = [];
		if (window.Bark.get_cookie('bp_spidh')) {
			psidh = [Bark.get_cookie('bp_spidh')];
			Bark.clear_cookie('bp_spidh', '/');
		}
		return api(
			// Need to remove the leading slash if we have a pathname
			pathname
				? `${pathname.substring(1)}/${search}`
				: `buyer/project/${projectId}/sellers/`,
			{ bpvid, filter, sort, pageNum, psidh },
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, 'Failed to get seller list');
				}
			},
			'GET',
			apiVersionHeader(version ?? 'v1'),
		);
	};

	/**
	 *
	 * @param projectId
	 * @param options.sort can be 'best|review|resp_time' -- this function will handle the ordering
	 * @param options.page integer > 0
	 * @param success
	 * @param failure
	 * @returns {*}
	 */
	const getMatchesListForProject = (projectId, options, success, failure) => {
		let {
			pathname,
			search,
			bpvid,
			filter,
			version,
			sort,
			page,
			filterQuery,
			isMobileWeb,
		} = options;
		// Need to remove the leading slash if we have a pathname
		if (sort === 'review') {
			sort = '-review';
		}

		let pathUri = pathname
			? `${pathname.substring(1)}/${search}`
			: `buyer/project/${projectId}/suggestions/`;
		if (filterQuery) {
			pathUri = `${pathUri}?${filterQuery}`;
		}

		return api(
			pathUri,
			{ bpvid, filter, sort, page, mw: isMobileWeb ? 1 : 0 },
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, 'Failed to get seller list');
				}
			},
			'GET',
			apiVersionHeader(version ?? 'v1'),
		);
	};

	const getResponseListForProject = (projectId, options, success, failure) => {
		let { bpvid, version, isMobileWeb } = options;
		return api(
			`buyer/project/${projectId}/responded_sellers/`,
			{ bpvid, mw: isMobileWeb ? 1 : 0 },
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, 'Failed to get seller response list');
				}
			},
			'GET',
			apiVersionHeader(version ?? 'v1'),
		);
	};

	const getSuggestionsForProject = (projectId, options) => {
		if (window.Bark.get_cookie('bp_spidh')) {
			const spidh = Bark.get_cookie('bp_spidh');
			// amend the options to include the spidh
			options['psidh'] = [spidh];
			// delete the cookie
			Bark.clear_cookie('bp_spidh', '/');
		}
		return api(`buyer/project/${projectId}/suggestions/`, options);
	};

	const getProjectById = (id, success, failure) => {
		api(
			`buyer/project/${id}/`,
			{},
			(res) => {
				success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, 'Failed to get projects');
				}
			},
		);
	};

	const getProjectPromise = (id, sidh) => {
		return api(
			`buyer/project/${id}/`,
			{ sidh },
			() => {},
			(error) => {
				defaultFailCallback(error, 'Failed to get project promise');
			},
		);
	};

	const getPojectBookings = (buyer_id, project_id) => {
		return api(
			`${window.Bark.ENV.api_hostname.replace(
				/(-[0-9]+)-api.d.bark.com/,
				'-1-api.d.bark.com',
			)}/bookings/buyer/${buyer_id}/bookings?projects=${project_id}`,
			{},
			() => {},
			(error) => {
				defaultFailCallback(error, 'Failed to get project promise');
			},
		);
	};

	const getSelf = (success, failure) => {
		api(
			'buyer/self/',
			{},
			(res) => {
				success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, 'Failed to self');
				}
			},
		);
	};

	const postResponseAction = (data, success, failure) => {
		let { projectId, sellerProfileId, type, source, bpvsid, pendingInteractionId } = data;
		if (!projectId) {
			bugsnagClient.notify(new Error('ProjectId undefined for interaction'), {
				metaData: data,
			});
		}
		api(
			`buyer/project/${projectId}/sellers/${sellerProfileId}/actions`,
			{
				action: type,
				bpvs: bpvsid,
				source: source,
				pending_interaction_id: pendingInteractionId,
			},
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, `Failed to record action of type ${type}`);
				}
			},
			'POST',
		);
	};

	const postResponseActionPromise = (data) => {
		let { projectId, sellerProfileId, type, source, bpvsid, pendingInteractionId } = data;
		return api(
			`buyer/project/${projectId}/sellers/${sellerProfileId}/actions`,
			{
				action: type,
				bpvs: bpvsid,
				source: source,
				pending_interaction_id: pendingInteractionId,
			},
			() => {},
			() => {},
			'POST',
		);
	};

	const postBulkResponseAction = (data, success, failure) => {
		let {
			type,
			source,
			sellerProfileIdArray,
			projectId,
			bpvid,
			log_event = false,
		} = data;
		if (!projectId) {
			bugsnagClient.notify(new Error('ProjectId undefined for bulk interaction'), {
				metaData: data,
			});
		}
		api(
			`buyer/project/${projectId}/actions`,
			{
				action: type,
				bpv: bpvid,
				source: source,
				spidh: sellerProfileIdArray,
				log_event: log_event,
			},
			(res) => {
				success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, `Failed to record action of type ${type}`);
				}
			},
			'POST',
		);
	};

	const sendSellerAMessage = (
		{ sellerProfileId, projectId, message, bpvs, source },
		success,
		failure,
	) => {
		api(
			`/buyer/project/${projectId}/sellers/${sellerProfileId}/messages`,
			{ message, source, bpvs },
			(res) => {
				success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, `Failed to send a buyer message`);
				}
			},
			'POST',
		);
	};

	const sendManySellersAMessage = (
		{ message, bpvid, source, spidhArray, projectIdHash },
		success,
		failure,
	) => {
		api(
			`/buyer/project/${projectIdHash}/messages`,
			{
				message: message,
				bpv: bpvid,
				source: source,
				spidh: spidhArray,
			},
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, `Failed to send a buyer message`);
				}
			},
			'POST',
		);
	};

	const getSelfPromise = (success, failure) => {
		return api(
			'buyer/self/',
			{},
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, 'Failed to self');
				}
			},
		);
	};

	const getResponsePreference = ({ projectIdHash }, success, failure) => {
		return api(
			`/buyer/project/${projectIdHash}/response-preference`,
			{},
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, `Failed to get response preference for ${name}`);
				}
			},
		);
	};

	const updateResponsePreference = ({ projectIdHash, value }, success, failure) => {
		return api(
			`/buyer/project/${projectIdHash}/response-preference`,
			{
				value,
			},
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(
						error,
						`Failed to update response preference for ${name} with ${value}`,
					);
				}
			},
			'POST',
		);
	};

	const getUrgentRequestSetting = ({ projectIdHash }, success, failure) => {
		return api(
			`/buyer/project/${projectIdHash}/urgent-request`,
			{},
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, `Failed to get urgent request setting`);
				}
			},
		);
	};

	const updateUrgentRequestSetting = ({ projectIdHash, value }, success, failure) => {
		return api(
			`/buyer/project/${projectIdHash}/urgent-request`,
			{
				value,
			},
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, `Failed to update urgent request setting`);
				}
			},
			'POST',
		);
	};

	const getBookableSellersForProject = (
		categoryId,
		countryId,
		options,
		success,
		failure,
	) => {
		return api(
			// Need to remove the leading slash if we have a pathname
			`/facade/bookable-sellers/cards`,
			{ category_id: categoryId, country_id: countryId, ...options },
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, 'Failed to get seller list');
				}
			},
			'GET',
		);
	};

	const blockBookableSellersForBuyer = (buyerId, success, failure) => {
		return api(
			// Need to remove the leading slash if we have a pathname
			`/facade/buyers/${buyerId}/bookable-sellers`,
			{},
			() => {
				success && success();
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, 'Failed to get seller list');
				}
			},
			'DELETE',
		);
	};

	const defaultFailCallback = (error, message) => {
		bugsnagClient.notify(new Error(message), { metaData: error });
	};

	const mockApi = (
		successResponse,
		failResponse,
		path,
		data,
		success,
		failure,
		method,
		headers,
		preventRefresh,
		contentType,
		processData,
	) => {
		setTimeout(() => {
			success(successResponse);
		}, 500);
	};

	const getMockSuccessResponseForAnimation = () => {
		return {
			data: {
				show_animation: true,
			},
		};
	};

	const getPendingInteractions = ({ projectIdHash }) => {
		return api(`/buyer/project/${projectIdHash}/actions/pending`);
	};

	const createEnquiry = (
		{ projectIdHash, sellerIdHash, isMarketplace },
		success,
		failure,
	) => {
		return api(
			`/buyer/project/${projectIdHash}/sellers/${sellerIdHash}/${
				isMarketplace ? 'marketplace-enquiry' : 'enquiry'
			}`,
			{},
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, `Failed to create enquiry`);
				}
			},
			'POST',
		);
	};

	const getOrAssignToExperiment = (experimentName, project_id, success, failure) => {
		return api(
			'user/experiments/get-or-assign',
			{ experimentName, project_id },
			(res) => {
				success && success(res);
			},
			(error) => {
				if (failure) {
					failure(error);
				} else {
					defaultFailCallback(error, `Error assigning expereiment ${experimentName}`);
				}
			},
			'POST',
		);
	};

	return {
		getProjectListForBuyer,
		getNextBarkSuggestions,
		getSellerListForProject,
		getMatchesListForProject,
		getResponseListForProject,
		getProjectPromise,
		getPojectBookings,
		getProjectById,
		mockApi,
		getMockSuccessResponseForAnimation,
		getSelf,
		postResponseAction,
		postResponseActionPromise,
		postBulkResponseAction,
		sendSellerAMessage,
		sendManySellersAMessage,
		getSelfPromise,
		getSuggestionsForProject,
		getResponsePreference,
		updateResponsePreference,
		getUrgentRequestSetting,
		updateUrgentRequestSetting,
		getPendingInteractions,
		createEnquiry,
		getBookableSellersForProject,
		blockBookableSellersForBuyer,
		getOrAssignToExperiment,
	};
}
