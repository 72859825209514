import { tracking as barkTrackingLogEvent, createEnquiry, showEnquiriesModal } from '@bark/bark-core';
const page = 'seller-profile';

export const handleEnquiry = (
	$button,
	sellerId,
	projectId,
	isMarketplace,
	trackingData,
	postCallBack,
) => {
	barkTrackingLogEvent('seller-profile', 'enquiries - call now - select', {
		...trackingData,
		page,
	});

	enquiryStartWaiting($button);
	createEnquiry({ projectIdHash: projectId, sellerIdHash: sellerId, isMarketplace })
		.then((response) => {
			const companyName = response?.data?.attributes?.company_name;
			const companyNumber = response?.data?.attributes?.telephone;
			const companyNumberFormatted = response?.data?.attributes?.telephone_formatted;
			const projectResponseHashId = response?.data?.id;
			window.location.assign(`tel:${companyNumberFormatted}`);
			showEnquiriesModal(
				sellerId,
				companyName,
				companyNumber,
				companyNumberFormatted,
				projectId,
				page,
				projectResponseHashId,
			);
			enquiryStopWaiting($button);
		})
		.catch((error) => {
			barkTrackingLogEvent('seller-profile', `enquiries - creating error`, {
				project_id: projectId,
				seller_profile_id: sellerId,
				page,
			});
			enquiryHandleError($button);
			enquiryStopWaiting($button);
			postCallBack();
		});
};

export const enquiryStartWaiting = ($button) => {
	$button.find('.btn-icon-text').removeClass('d-flex').addClass('d-none');
	$button.find('.spinner-border').removeClass('d-none').addClass('d-flex');
};

export const enquiryStopWaiting = ($button) => {
	$button.find('.btn-icon-text').removeClass('d-none').addClass('d-flex');
	$button.find('.spinner-border').removeClass('d-flex').addClass('d-none');
};

export const enquiryHandleError = ($button) => {
	const sellerId = $button.data('sellerId');
	$button.prop('disabled', true);
	$(`.enquiries-feedback[data-seller-id=${sellerId}]`).removeClass('d-none')
};
