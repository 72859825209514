import ListLoadingAnimation from "./list-loading-animation";
import BuyerNextBark from "./buyer-next-bark";
import {AfterBarkPlacedModal} from "./after-bark-placed";
import {shouldShowAdditionalDetails} from "./additional-details";

const state = {
    searchingAnimation: ListLoadingAnimation({providerNoun: 'professionals'}),
    shouldAnimate: false,
    isAnimating: false
}
const hasAnimationBeenShownBefore = () => {
    try {
        if (window.localStorage) {
            return !!window.localStorage.getItem(`animated-project-${state.projectDetail.id}`);
        }
    } catch (error) {
        bugsnagClient.notify(new Error('Failed to save the loading animation seen event in local storage.'), { metaData: error });
        return false;
    }
}

/**
 * Determine whether we should animate
 * @returns {boolean}
 */
export const shouldAnimate = ({firstViewUserFlow, disableAnimation}) => firstViewUserFlow && !hasAnimationBeenShownBefore() && !disableAnimation

export const toggleLoading = (show) => {
    $('#project-seller-list').toggleClass('waiting', show);
};

export const startAnimating = () => {
    state.searchingAnimation.startAnimation();
    state.shouldAnimate = true;
    state.isAnimating = true;
}

export const updateSearchingAnimationProviderNoun = (noun) => {
    if (noun) {
        state.searchingAnimation.setProviderNoun(noun);
    }
}

export const showSellersImmediately = () => {
    $('.buyer-journey-card').removeClass('js-animate');
}

const handleNoSellersLoadingAnimation = () => {
    state.searchingAnimation.finishAnimationAndGoToLastStep();
    state.searchingAnimation.renderWithNoAnimation()
}


const animateSellerSlideDown = (seller, duration) => {
    seller.slideDown(duration);
    seller.removeClass('js-animate');
}

const animateSellers = () => {
    let sellerToAnimate = getSellerToAnimate();

    if (sellerToAnimate.length === 0) {
        $('#project-seller-list').trigger('allSellersLoaded', []);
        return;
    }

    animateSellerSlideDown(sellerToAnimate, 400)

    setTimeout(() => {
        animateSellers(isLoadMore);
    }, 2000);
}


const getSellerToAnimate = () => {
    return $('.buyer-journey-card.js-animate').first();
}

const bindAnimationEventListeners = ($sellerList) => {
    $sellerList.on('searchingForSellersAnimationFinished', () => {
        state.isAnimating = false;
        try {
            if (window.localStorage) {
                window.localStorage.setItem(`animated-project-${state.projectDetail.id}`, '1');
            }
        } catch (e) {
        }
        if (showFirstViewUserFlow() || isFirstProjectView(state.projectDetail)) {
            experimentTracking.submitExpAct('searching-animation-finish');
        }
        showSellers();
    });

    $sellerList.on('allSellersLoaded', (event, data) => {
        renderShowMore();
        $('.js-load-more').removeClass('disabled');
        handleShowingRequestQuoteFromAll({});
        experimentTracking.submitExpAct('display-sellers-animation-finish');

        if (!data.isLoadMore) {
            BuyerNextBark({ barkMode: 'bnb-project-dash' }).getNextBarksAndAppend($('#next-bark-carousel'), $('#next-bark-carousel-mobile'));
        }
    });
}

export const handleNextBarkInPostBarkModal = ({showFirstViewFlow, project}) => {
    updateSearchingAnimationProviderNoun(project.attributes?.provider_noun_plural);
    // if this project has sellers, we have nothing to do here
    // or if the project is showing additional detaisl
    if (!showFirstViewFlow || shouldShowAdditionalDetails(showFirstViewFlow, project?.attributes)) {
        // if this isn't the first view from the seller, we can just get rid of the animation
        // if it is the first view we should wait until we show the next modal so the page isn't moving around a lot
        handleNoSellersLoadingAnimation();
    } else {
        const bnb = BuyerNextBark({
            cards: {
                desktopClasses: 'col-6 col-sm-4 px-2',
                labelClasses: 'mb-0 p-2 text-grey-400'
            },
            barkMode: 'bnb-modal'
        });
        const minBnbItems = 4;
        const maxBnbItems = 6;

        bnb.getNextBarksAndRender('.js-bark-placed-bnb-section', minBnbItems, maxBnbItems)
            .then(() => {
                handleNoSellersLoadingAnimation();
                AfterBarkPlacedModal({project}).showSuccessModal();
            })
            .catch((error) => {
                bugsnagClient.notify(new Error('Failed to get next bark data on a list page'), { metaData: {error, project} });
            })
    }
};


/**
 * @param data
 * @param {string} containerElementId
 * @param {string} listType
 */
const showSellers = (containerElementId = "seller-list", listType = 'project_sellers', data) => {
    let isLoadMore = !!data;

    if (!data) {
        data = state.fullSellerList;
    }

    if (!data || !projectHasSellers()) {
        $('#' + containerElementId).trigger('removeAllSkeletonsFromContainer');
        return;
    }
    let sellerHtml = createSellerRows(data, listType);
    $('#' + containerElementId).trigger('replaceSkeletonWithContent', [sellerHtml])

    // When we pass a list of sellers we want to animate it
    if (state.shouldAnimate || isLoadMore) {
        animateSellers(isLoadMore);
    } else {
        showSellersImmediately();
    }
}
