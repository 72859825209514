import { isProspectFindWorkPage, isProspectDashboardPage } from '../pages';
import { tracking, _t } from '@bark/bark-core';

export function initProspectsSignupJs() {

    const redirectStorageKey = 'prospect-rescue-incomplete';

    var errorAnimationTimeout = null;

    var payload = {};

    var userdata = {};

    var redirectTimeouts = [];

    let sellerProfileId;
    let is_lead_unavailable_flow = 0;
    let is_user_rescue_flow = 0;

    function attachEventHandlers() {

        $(".prospect-faq-link").on('click', function (e) {
            $("#prospectFAQModal").modal('show');
            logEvent("click", "prospect faq link");
            e.preventDefault();
            return false;
        });

        $(".lead-detail-link").on('click', function (e) {
            $("#leadDetailModal").modal('show');
            logEvent("click", "lead detail link");
            e.preventDefault();
            return false;
        });

        $(".prospect-signup-form-link").on('click', function (e) {
            $("#prospectSignupFormModal").modal('show');
            logEvent("click", "signup form link");
            tracking('prospect-signup', 'Select free lead - success', {
                project_id: $('#project-id').val(),
                category_id: payload.maincategoryid ?? $('#category-id').val(),
                is_user_rescue_flow,
                is_lead_unavailable_flow,
            });
            tracking('prospect-signup', 'Enter details - view', {
                category_id: payload.maincategoryid ?? $('#category-id').val(),
                project_id: $('#project-id').val(),
                is_user_rescue_flow,
                is_lead_unavailable_flow,
            });
            e.preventDefault();
            return false;
        });

        $('.prospect-profile-form input.form-control').on('keyup', function(e) {
            var t = $(this);
            if (t.val().trim().length > 0) {
                $(t).addClass('not-empty');
            } else {
                $(t).removeClass('not-empty');
            }
        });



        $(".prospect-signup-button").off('click.submit').on('click.submit', validateSignup);

        // For iOS, trigger the focus state on the input field
        $(".form-control-placeholder").on('click.focus', function () {
            if (Bark.isMobile()) {
                $(this).siblings('.form-control').eq(0).focus();
            }
        });

        // Project undo not interested action
        $('body').on('click', '.undo-not-interested-button', function (e) {
            Bark.showLoading();

            var data = {
                "recordType": 'prospect',
                "recordId": $("#prospect-id").val(),
                "projectId": $("#project-id").val(),
                "declineType": 99
            };

            $.ajax({
                type: "POST",
                url: "/response_declined_delete/",
                data: data,
                success: function (data) {
                    $(".not-interested-confirmation").fadeOut();
                    Bark.hideLoading();
                    $(".not-interested-link").removeClass('d-none').addClass('d-block');
                }
            });

            e.preventDefault();
            return false;
        });
    }

    /**
     * Checks to see if the supplied email address is in a valid format using basic validation.
     * @returns {boolean}
     */
    function emailIsValidFormat() {
        var email = $("#email").val();
        if (!email || !email.match(/.+@.+\..+/)) {
            return false;
        }
        return true;
    }

    /**
     * Logs a signup event
     * @param eventType The event type. One of: begin, click, validation_error, api_error, seller_exists, buyer_exists, lead_results, success
     * @param eventField If applicable, the related field
     * @param eventValue If applicable, the related field value
     */
    function logEvent(eventType, eventField, eventValue) {
        $.post(
            "/api/seller-signup-event-log/",
            {
                "signup_type": "prospect",
                "event_type": eventType,
                "event_field": eventField,
                "event_value": eventValue
            });
    }

    /**
     * Redirect to the seller page. This is done by scheduling a number of checks for leads, and redirecting based on those results.
     */
    function redirectToSellerPage () {
        const maxAttempts = 10;
        try {
            // set up a request to run each second
            for (var seconds = 1; seconds <= maxAttempts; seconds++) {
                scheduleLeadCheck(seconds, (seconds === maxAttempts));
            }

            // After the other attempts have expired, assume no leads and redirect to the seller home
            redirectTimeouts.push(
                window.setTimeout(function () {
                    tracking('prospect-signup', 'Navigating to seller dashboard - rescue attempt', {
                        reason: 'no_leads',
                        attempt: maxAttempts + 1,
                        project_id: $('#project-id').val(),
                        prospect_id: $('#prospect-id').val(),
                        seller_profile_id: sellerProfileId,
                        is_user_rescue_flow,
                        is_lead_unavailable_flow,
                    });

                    logEvent("lead_results", "false", (maxAttempts + 1));
                    clearRedirectStorageKey();
                    location.href = '/sellers/dashboard/?noleads=1';
                }, (maxAttempts + 2) * 1000)
            );
        } catch(e) {
            // Some sort of error occurred, just redirect to the dashboard
            tracking('prospect-signup', 'Navigating to seller dashboard - rescue attempt', {
                reason: 'general_error',
                attempt: -1,
                project_id: $('#project-id').val(),
                prospect_id: $('#prospect-id').val(),
                seller_profile_id: sellerProfileId,
                is_user_rescue_flow,
                is_lead_unavailable_flow,
            });

            logEvent("general_error", "redirectToSellerPage", e.message);
            bugsnagClient.notify(e);
            clearRedirectStorageKey();
            location.href='/sellers/dashboard/';
        }
    }

    /**
     * Schedules a check to see if any leads have been identified. If so, redirect to the leads dashboard and cancel all other checks
     * @param seconds The number of seconds to wait.
     */
    function scheduleLeadCheck(seconds, isFinalCheck) {
        redirectTimeouts.push(
            window.setTimeout(function () {
                var handle,
                    projectId = $('#project-id').val();
                Bark.api(
                    'seller/barks',
                    {
                        is_prospect_response: 1,
                        project_id: projectId,
                        results_per_request: 1
                    },
                    function (barks) {
                        // If they have any leads, take them to the lead dashboard, otherwise to the seller home dashboard
                        if (barks.data && barks.data.items && barks.data.items.length) {
                            // If they have a specific lead, wait to redirect to see if that actual lead came back.
                            // It may not come back on first check if the robot is still running
                            var isLeadFound = false;
                            if (projectId) {
                                for (var lead in barks.data.items) {
                                    if (barks.data.items[lead].project_id == projectId) {
                                        isLeadFound = true;
                                    }
                                }
                            }
                            // Ready to redirect if:
                            // 1) They did not sign up for a specific lead
                            // 2) They did sign up for a specific lead, and it has been matched to their account
                            // 3) This is the final time we are checking
                            if (!projectId || isLeadFound || isFinalCheck) {
                                while (handle = redirectTimeouts.pop()) {
                                    window.clearTimeout(handle);
                                }

                                tracking('prospect-signup', 'Navigating to seller dashboard', {
                                    attempt: seconds,
                                    project_id: $('#project-id').val(),
                                    prospect_id: $('#prospect-id').val(),
                                    seller_profile_id: sellerProfileId,
                                    is_user_rescue_flow,
                                    is_lead_unavailable_flow,
                                });

                                logEvent("lead_results", "true", seconds);
                                const hasQueryString = isProspectFindWorkPage() || isProspectDashboardPage();
                                const queryString = hasQueryString && isProspectFindWorkPage() ? 'new=pros-link' : 'new=pros-dashboard';
                                clearRedirectStorageKey();
                                location.href = `/sellers/dashboard/${hasQueryString ? '?' : ''}${queryString}`;
                            } else {
                                while (handle = redirectTimeouts.pop()) {
                                    window.clearTimeout(handle);
                                }

                                tracking('prospect-signup', 'Navigating to seller dashboard - rescue attempt', {
                                    reason: 'no_leads',
                                    attempt: seconds,
                                    project_id: $('#project-id').val(),
                                    prospect_id: $('#prospect-id').val(),
                                    seller_profile_id: sellerProfileId,
                                    is_user_rescue_flow,
                                    is_lead_unavailable_flow,
                                });

                                clearRedirectStorageKey();
                                location.href = `/sellers/dashboard/?noleads=1`;
                            }
                        }
                    },
                    function (e) {
                        console.error(e);
                        logEvent("api_error", "scheduleLeadCheck", e.message);
                        bugsnagClient.notify(e);

                        tracking('prospect-signup', 'Navigating to seller dashboard - rescue attempt', {
                            reason: 'lead_check_error',
                            attempt: seconds,
                            project_id: $('#project-id').val(),
                            prospect_id: $('#prospect-id').val(),
                            seller_profile_id: sellerProfileId,
                            is_user_rescue_flow,
                            is_lead_unavailable_flow,
                        });

                        clearRedirectStorageKey();
                        location.href = '/sellers/dashboard/';
                    },
                    'GET',
                    Bark.apiVersionHeader('v2')
                );
            }, seconds * 1000)
        );
    }

    const setSellerProfileId = async () => {
        try {
            const response = await Bark.api('seller/self');
            sellerProfileId = response?.data?.spf_id;
        } catch (e) {
            tracking('prospect-signup', 'Failed to get seller profile id', {
                project_id: $('#project-id').val(),
                prospect_id: $('#prospect-id').val(),
                is_user_rescue_flow,
                is_lead_unavailable_flow,
            });
        }
    };

    const setRedirectStorageKey = () => {
        if (!window.localStorage) {
            return;
        }

        window.localStorage.setItem(redirectStorageKey, '1');
    };

    const clearRedirectStorageKey = () => {
        window.localStorage?.removeItem(redirectStorageKey);
    };

    window.addEventListener('storage', function (e) {
        if (e.key === redirectStorageKey && e.newValue === null) {
            // The poll key has been cleared
            window.setTimeout(() => {
                document.location = `/sellers/my-barks/?prx=${$("#project-id").val()}`;
            }, 5000);
        }
    });

    const existingUserRescueFlow = () => {
        const modal = $('#user-exists-in-system-modal');
        modal.modal('show');

        setRedirectStorageKey();

        $('.js-user-exists-retry-submit', modal).off('click.retry').on('click.retry', () => {
            modal.modal('hide');

            tracking('prospect-signup', 'Existing user rescue flow - retry submit', {
                project_id: $('#project-id').val(),
                prospect_id: $('#prospect-id').val(),
                is_user_rescue_flow,
                is_lead_unavailable_flow,
            });

            validateSignup();
        });

        tracking('prospect-signup', 'Existing user rescue flow - show modal', {
            project_id: $('#project-id').val(),
            prospect_id: $('#prospect-id').val(),
            is_user_rescue_flow,
            is_lead_unavailable_flow,
        });

        try {
            $(modal.data('bs.modal')._backdrop).css({backgroundColor: 'rgb(17, 22, 55)'})
        } catch (e) {}
    };

    function submitSignup() {

        Bark.json({
            url: '/sellers/save-new-account/',
            data: {payload: JSON.stringify(payload)}
        }).done(async function (e) {
            if (e.result === 'OK') {
                try {
                    // Store the login token
                    logEvent("success", "", "");

                    tracking('prospect-signup', 'Enter details - success', {
                        category_id: payload.maincategoryid ?? $('#category-id').val(),
                        project_id: $('#project-id').val(),
                        is_user_rescue_flow,
                        is_lead_unavailable_flow,
                    });
                    tracking('prospect-signup', 'Signup - success', {
                        project_id: $('#project-id').val(),
                        category_id: payload.maincategoryid ?? $('#category-id').val(),
                        is_user_rescue_flow,
                        is_lead_unavailable_flow,
                    });

                    window.Bark.ENV.JWT = e.data.jwt;

                    await setSellerProfileId();

                    // Start a process to determine the correct page to redirect to
                    redirectToSellerPage();
                    return;
                } catch(e) {
                    // Some sort of error occurred, just redirect to the dashboard
                    logEvent("general_error", "submitSignup", e.message);
                    bugsnagClient.notify(e);

                    tracking('prospect-signup', 'Navigating to seller dashboard - rescue attempt', {
                        reason: 'general_error',
                        attempt: -1,
                        project_id: $('#project-id').val(),
                        prospect_id: $('#prospect-id').val(),
                        seller_profile_id: sellerProfileId,
                        is_user_rescue_flow,
                        is_lead_unavailable_flow,
                    });

                    clearRedirectStorageKey();

                    location.href='/sellers/dashboard/';
                }
                return;
            } else if (e.errcode === Bark.consts.EX_EMAIL_EXISTS_IN_SELLERS) {
                tracking('prospect-signup', 'Error saving account', {
                    reason: 'email_exists_in_sellers',
                    project_id: $('#project-id').val(),
                    prospect_id: $('#prospect-id').val(),
                    is_user_rescue_flow,
                    is_lead_unavailable_flow,
                });

                Bark.hideLoading();
                unblockSubmit();
                $("#prospectSignupFormModal").modal('hide');

                logEvent("seller_exists", "email", $("#email").val());
                existingUserRescueFlow();
                return;
           } else if (e.errcode === Bark.consts.EX_EMAIL_EXISTS_IN_BUYERS) {
                tracking('prospect-signup', 'Error saving account', {
                    reason: 'email_exists_in_buyers',
                    project_id: $('#project-id').val(),
                    prospect_id: $('#prospect-id').val(),
                    is_user_rescue_flow,
                    is_lead_unavailable_flow,
                });

                Bark.hideLoading();
                unblockSubmit();
                $("#prospectSignupFormModal").modal('hide');

                logEvent("buyer_exists", "email", $("#email").val());
                existingUserRescueFlow();
                return;
            } else if (e.data.code == 400) {
                tracking('prospect-signup', 'Error saving account', {
                    reason: 'general_error',
                    project_id: $('#project-id').val(),
                    prospect_id: $('#prospect-id').val(),
                    is_user_rescue_flow,
                    is_lead_unavailable_flow,
                });

                Bark.hideLoading();
                unblockSubmit();
                $("#prospectSignupFormModal").modal('show');
                $("#name").addClass('is-invalid');
                return;
            } else if (e.data.code == 422) {
                Bark.hideLoading();
                Bark.alertModal(e.result, _t('public_prospects-signup:close-header'));
                return;
            }
            Bark.hideLoading();
            Bark.alertModal(e.result, _t('public_prospects-signup:close-header'));
            unblockSubmit();
        }).fail(function () {
            tracking('prospect-signup', 'Error saving account', {
                reason: 'general_error',
                project_id: $('#project-id').val(),
                prospect_id: $('#prospect-id').val(),
                is_user_rescue_flow,
                is_lead_unavailable_flow,
            });

            Bark.hideLoading();
            $("#prospectSignupFormModal").modal('hide');
            unblockSubmit();
            Bark.alertModal(_t('public_prospects-signup:error-saving-account'), _t('public_prospects-signup:close-header'));
        });

    }

    /**
     * Validates the phone number, if supplied. If valid or empty, moves to the next step and submits signup.
     * @returns {boolean}
     */
    function validatePhone() {
        // Phone is not required
        if (!userdata.tel) {
            validateCompanyName();
            return;
        }

        Bark.validate.telephone(userdata.tel, function (responseObject) {
            if (responseObject.status) {
                userdata.phone_type = responseObject.lineType;
                validateCompanyName();
            } else {
                unblockSubmit();

                $("#phone").addClass('is-invalid');
                $('#phone-row-error').text(_t('public_prospects-signup:phone-validation-message')).removeClass('d-none');
                logEvent("validation_error", "telephone_external", userdata.tel);
                Bark.hideLoading();
                Bark.addErrorAnimation($("#phone"), true);
            }
        }, true);
    }

    /**
     * Check name fields to make sure they dont contain any contact info.
     * If passes, moves on to final form submission
     */
    function validateCompanyName()
    {
        Bark.api(
            '/contentfilter/contactdetails',
            {
                'contents': {
                    'company-name': userdata.companyname,
                    'name': userdata.name
                }
            },
            function(data) {
                if (data.status) {
                    submitSignup();
                } else {
                    let failed = data.failures;
                    for (var i=0; i< failed.length; i++) {
                        var fieldname = failed[i];
                        var el = $('#' +fieldname);
                        el.addClass('is-invalid');
                        $('#'+fieldname+'-row-error').text(_t('public_prospects-signup:no-contact-details'));
                        logEvent("validation_error", fieldname);
                        Bark.addErrorAnimation($("#"+fieldname), true);
                    }
                    unblockSubmit();
                }
            },
            function(error) {
                window.bugsnagClient.notify(error);
                submitSignup();
            },
            'POST',
            Bark.apiVersionHeader('v1')
        );
    }

    function blockSubmit() {
        $('.prospect-signup-button').attr('disabled', true).html(
            '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> '+_t('common:loading-message')
        );
    }

    function unblockSubmit() {
        $('.prospect-signup-button').attr('disabled',false).html(_t('public_prospects-signup:continue-button'));
    }

    /**
     * Validates the signup form, and proceeds towards submission if valid.
     * @param e
     * @returns {boolean}
     */
    function validateSignup(e) {
        $(".is-invalid").removeClass('is-invalid');
        $(".invalid-feedback").removeClass('d-none');
        const emailField = $('#email');

        userdata = {
            companyname: $('#company-name').val(),
            email: emailField.length ? emailField.val() : undefined,
            tel: $('#phone').val(),
            website: $('#website').val(),
            prospect_id: $('#prospect-id').val(),
            name: $('#name').val().trim(),
        };

        var invalidFields = [];

        if (!userdata.name || userdata.name.length < 2) {
            invalidFields.push('name');
            logEvent("validation_error", "name", userdata.name);
            Bark.addErrorAnimation($("#name"), true);
        }

        if (emailField.length && (!userdata.email || !emailIsValidFormat())) {
            invalidFields.push('email');
            logEvent("validation_error", "email_local", userdata.email);
            Bark.addErrorAnimation($("#email"), true);
            $("#email-row-error").html(_t('public_prospects-signup:email-validation-message'));
        }

        // Basic website validation
        if (userdata.website) {
            if (!userdata.website.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)) {
                $("#website").addClass('is-invalid');
                $('#website-row-error').text(_t('public_prospects-signup:web-address-validation-message')).removeClass('d-none');
                Bark.addErrorAnimation($('#website'), true);
                logEvent("validation_error", "website", userdata.website);
                invalidFields.push('website');
            }
        }

        // Basic phone validation
        if (userdata.tel) {
            var teltest = window.basicPhoneCheck.test(userdata.tel);
            if (teltest.invalid || teltest.badChars) {
                logEvent("validation_error", "telephone_local", userdata.tel);
                invalidFields.push('phone');
                Bark.addErrorAnimation($("#phone"), true);
            }
        }

        if (invalidFields.length) {
            Bark.hideLoading();
            for (var index in invalidFields) {
                var field = invalidFields[index]
                $("#" + field).addClass('is-invalid');
                $("#" + field + "-row-error").removeClass('d-none');
            }
            Bark.hideLoading();
            return false;
        }

        var remote = "0";
        if ($("#service-remote").is(':checked')) {
            remote = "1";
        }
        else if ($("#service-nationwide").val() == "1") {
            remote = "1";
        }

        const cityId = $('#city-id').val();

        payload = {
            maincategoryid: $('#category-id').val(),
            othercategories: $('#category-ids').val() ? $('#category-ids').val().split(',') : '',
            userdata: userdata,
            location: {
                city_type: 'c',
                id: $('#city-id').val(),
                lat: $('#latitude').val(),
                lng: $('#longitude').val(),
                name: $('#city-name').val(),
                radius: undefined, // This will be calculated in the signup process
                shapes: undefined,
                subtype: undefined,
                type: cityId ? 'radius' : 'nationwide',
                value: undefined,
                remote: remote
            },
            business_log: {
                business_id: null,
                prospect_id: $('#prospect-id').val(),
                project_id: $('#project-id').val(),
            }
        }

        // Check the email address with Mailgun
        blockSubmit();

        if (emailField.length) {
            emailField.email_validator({
                success: function (data) {
                    if (data.is_valid) {
                        validatePhone();

                    } else {
                        unblockSubmit();
                        $('.prospect-signup-button').attr('disabled', false).html('Continue');

                        var emailErrorMsg = _t('public_prospects-signup:email-validation-message');

                        if (data.did_you_mean) {
                            emailErrorMsg = _t(
                                'public_prospects-signup:did-you-mean-message',
                                {
                                    did_you_mean_link: Bark.sprintf('<a href="#" className="email-did-you-mean">%s</a>', data.did_you_mean),
                                    interpolation: {escapeValue: false}
                                }
                            );
                            logEvent("validation_error", "email_external_suggestion", $("#email").val());
                        } else {
                            logEvent("validation_error", "email_external", $("#email").val());
                        }

                        $('#email-row-error').html(emailErrorMsg).removeClass('d-none');
                        $("#email").addClass('is-invalid');

                        if (data.did_you_mean) {
                            $('.email-did-you-mean').off('click').on('click', function (e) {
                                logEvent("suggestion_accepted", $("#email").val(), data.did_you_mean);
                                $('#email').val(data.did_you_mean);
                                $('#email-row-error').addClass('d-none');
                                $("#email").removeClass('is-invalid');
                                e.preventDefault();
                                return false;
                            });
                        }


                        Bark.addErrorAnimation($('#email'), true);
                        Bark.hideLoading();
                    }
                },
                error: function () {
                    unblockSubmit();
                    var emailErrorMsg = _t('public_prospects-signup:email-validation-message');

                    $('#email-row-error').html(emailErrorMsg).removeClass('d-none');
                    $("#email").addClass('is-invalid');

                    Bark.addErrorAnimation($('#email'), true);
                    Bark.hideLoading();
                    logEvent("validation_error", "email_external", $("#email").val());
                }
            });
        } else {
            // There is a logged-in user here so there isn't an email field to validate
            validatePhone();
        }
    }

    const autoSubmitIfRequired = () => {
        const params = new URLSearchParams(location.search);

        if (!params.has('aus')) {
            return;
        }

        params.delete('aus');
        window.history?.replaceState(null, null, `?${params.toString()}`);

        tracking('prospect-signup', 'Existing user rescue flow - auto-submit', {
            project_id: $('#project-id').val(),
            prospect_id: $('#prospect-id').val(),
            is_user_rescue_flow,
            is_lead_unavailable_flow,
        });

        validateSignup();
    };

    const showErrorModalIfRedirect = () => {
        const params = new URLSearchParams(location.search);

        if (!params.has('bn')) {
            return;
        }

        is_lead_unavailable_flow = 1;

        const buyerName = decodeURIComponent(params.get('bn'));
        const modal = $('#lead-no-longer-available-modal');
        params.delete('bn');

        window.history?.replaceState(null, null, `?${params.toString()}`);

        $('.js-unavailable-modal-title', modal).text(
            _t('public_prospects-signup:lead-no-longer-available.title', { buyer_public_name: buyerName })
        );

        $('.js-unavailable-modal-subtitle', modal).text(
            _t('public_prospects-signup:lead-no-longer-available.subtitle', { buyer_public_name: buyerName })
        );

        $('.js-unavailable-modal-confirm', modal).off('click.confirm').on('click.confirm', () => {
            tracking('prospect-signup', 'Redirect Alert - Confirm', {
                project_id: $('#project-id').val(),
                prospect_id: $('#prospect-id').val(),
                is_user_rescue_flow,
                is_lead_unavailable_flow,
            });
            modal.modal('hide');
            $(".prospect-signup-form-link").click();
        });

        $('.js-unavailable-modal-cancel', modal).off('click.cancel').on('click.cancel', () => {
            tracking('prospect-signup', 'Redirect Alert - Close', {
                project_id: $('#project-id').val(),
                prospect_id: $('#prospect-id').val(),
                is_user_rescue_flow,
                is_lead_unavailable_flow,
            });
        });

        modal.modal('show');

        try {
            $(modal.data('bs.modal')._backdrop).css({backgroundColor: 'rgb(17, 22, 55)'})
        } catch (e) {}
    };

    $(document).ready(function () {
        is_user_rescue_flow = Number($('#user-rescue-flow').val()) === 1;
        attachEventHandlers();
        logEvent("begin");
        showErrorModalIfRedirect();

        tracking('prospect-signup', 'Start prospect sign-up', {
            project_id: $('#project-id').val(),
            category_id: payload.maincategoryid ?? $('#category-id').val(),
            is_user_rescue_flow,
            is_lead_unavailable_flow,
        });

        if ($('.prospect-dashboard-signup-form-container').length > 0) {
            tracking('prospect-signup', 'Enter details - view', {
                category_id: payload.maincategoryid ?? $('#category-id').val(),
                project_id: $('#project-id').val(),
                is_user_rescue_flow,
                is_lead_unavailable_flow,
            });
        } else if ($('.prospect-leads-list-bk').length > 0) {
            tracking('prospect-signup', 'Select free lead - view', {
                category_id: payload.maincategoryid ?? $('#category-id').val(),
                project_id: $('#project-id').val(),
                is_user_rescue_flow,
                is_lead_unavailable_flow,
            });
        }

        autoSubmitIfRequired();
    });
}
