export default function () {
	var Bark = (window.Bark = window.Bark || {});

	var srv = window.srv || '';

	/**
	 * Logs a signup event
	 * @param eventType The event type. One of: begin, click, validation_error, api_error, seller_exists, buyer_exists, lead_results, success
	 * @param eventField If applicable, the related field
	 * @param eventValue If applicable, the related field value
	 */
	function logEvent(eventType, eventField, eventValue) {
		$.post('/api/seller-signup-event-log/', {
			signup_type: 'organic',
			event_type: eventType,
			event_field: eventField,
			event_value: eventValue,
		});
	}

	$('document').ready(function () {
		logEvent('begin', null, srv);
		Bark.triggerHotjarRecording('seller_signup_conversion');
	});
}
