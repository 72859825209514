import {_t} from '@bark/bark-core';
import { momentLocale } from "@bark/bark-core";
const moment = momentLocale();

export default function(){

    const formatMessage = (lastMessage, companyName) => {

        let formattedLastMessage = null;

        if(lastMessage) {
            let formattedDate = formatDate(lastMessage.time);
            let formattedSender;
            formattedSender = lastMessage.sender == 'buyer' ? _t('buyer_seller-list:last-message.buyer-self') : companyName

            formattedLastMessage = {
                message: lastMessage.message,
                sender: formattedSender,
                time: formattedDate,
            }
        }

        return formattedLastMessage
    }

    function formatDate(unixTimestamp) {

        if(!isNaN(unixTimestamp)) {
            return moment.unix(unixTimestamp).format('D MMM, HH:mm');
        } else {
            return '';
        }
    }

    return {
        formatMessage
    }
}

