import { tracking, _l } from '@bark/bark-core';
import { getLang } from '@bark/bark-core/src/helpers/locale';

function setSuggestionsPosition() {
	const categoryInput = $('#category');

	if (categoryInput.length) {
		const offset = categoryInput.offset();
		$('.streamcomplete-body').css({
			width: categoryInput.outerWidth(),
			top: offset.top + 60,
			left: offset.left,
		});
	}
}

  /**
   * Track events with GTM
   * @param {string} eventName The name of the event
   * @param {object} data [optional] Any extra data to pass through (most is passed through already)
   */
  function tagManager(eventName, data = {}) {
    if (!window.dataLayer) {
      return;
    }
    try {
      window.dataLayer.push(
        $.extend(
          {
            event: eventName,
            country_id: window.Bark.ENV.ccid || null,
            lang: window.Bark.ENV.lang || null,
            locale: window.Bark.ENV.locale || null,
            campaign: window.Bark.ENV.campaign || null,
          },
          data || {},
        ),
      );
    } catch (ex) {
      window.bugsnagClient && window.bugsnagClient.notify(ex);
    }
  }

export default function () {
	var categoryId = '';
	var categoryName = '';

	$(document).ready(function () {
        if (!$('#__SellerSignup').length) {
            tracking('organic-seller-signup', 'Start organic sign up');
            tracking('organic-seller-signup', 'Enter service - view');
            tagManager('seller-signup:start');

            $('.js-category-option-trk')
                .off('click.trk')
                .on('click.trk', ({ currentTarget }) => {
                    tracking('organic-seller-signup', 'Enter service - clicked category icon', {
                        category_id: $(currentTarget).data('id'),
                    });
                });
        }
	});

	var categoryInput = $('#category');

	if (categoryInput.length) {
		// Reset form fields
		categoryInput.val('');

		$(window).resize(function () {
			setSuggestionsPosition();
		});

		// Scroll up when the bottom 'get started' is clicked
		$('.js-get-started').click(function () {
			var duration = 500;
			if (Bark.isMobile()) {
				duration = 0;
			}
			$('html, body').animate({ scrollTop: 0 }, { duration: duration });
			categoryInput.focus();
		});

		// Category autocomplete
		categoryInput
			.streamComplete({
				src: `${window.Bark.ENV.api_hostname}/services-hashed`,
				usestandardsyntax: true,
				requestmethod: 'GET',
				delay: 10,
                extraAttributes: {'data-cy': 'autocomplete-results-container'},
				onbeforesearch: function (payload) {
					payload.coid = window.Bark.ENV.ccid;
				},
				preprocessresults: function (data) {
					if (data.length > 10) {
						data = data.slice(0, 10);
					}
					data = data.map(item => {
						item['label'] = item['name'];
						return item;
					})
					return data;
				},
				onopen: function (results, outputbounds) {
					var categoryField = $('#category');
					var categoryFieldOffset = categoryField.offset();

					outputbounds.width = categoryField.outerWidth();
					outputbounds.left = categoryFieldOffset.left;
					outputbounds.top += 10;
				},
				onclose: function () {
					$(this).removeClass('ajax-loading');
				},
				onresponse: function () {
					$(this).removeClass('ajax-loading');
				},
				onbeforeajaxsearch: function () {
					categoryId = '';
					categoryName = '';
					$(this).addClass('ajax-loading');
				},
				onselect: function (cur) {
					setCategory(cur);
					// submitSellerSignUpForm();
				},
			})
			.data('streamcomplete');

		categoryInput.on('keyup', function (e) {
			// Reset the category if input value emptied
			$('.for-category').slideUp({ duration: 100 });
			if ($(this).val() == '') {
				categoryId = '';
				categoryName = '';
			}

			// Try to set a category based on the value when Enter is Hit
			if (e.keyCode === 13) {
				attemptItemSelectFromAutocomplete();
			}
		});
	}

	// Click the submit to submit the form
	$('.create-user-button').click(function () {
		submitSellerSignUpForm();
	});

	// Populaar parent category clicked
	$('.parent-categories .parent-category').click(function (e) {
		var id = $(this).attr('id');
		selectCategoryGroup(id, true);
	});

	function isInViewport(element, pixelsInViewPort) {
		var elementTop = element.offset().top;
		var elementBottom = elementTop + element.outerHeight();

		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();

		return (
			elementBottom - pixelsInViewPort > viewportTop &&
			elementTop + pixelsInViewPort < viewportBottom
		);
	}

	function selectCategoryGroup(id, scroll) {
		// select the clicked item
		$('.parent-category').removeClass('selected');
		$('.parent-category#' + id).addClass('selected');

		id = id.replace('parent-', 'group-');

		var selectedGroup = $('.child-category-group.' + id);

		// show child categories group
		$('.child-category-group').hide();
		selectedGroup.fadeIn({ duration: 100 });

		if (scroll && !isInViewport(selectedGroup, 50)) {
			var scrollTop = selectedGroup.offset().top - 200;
			$('html, body').animate({ scrollTop: scrollTop }, { duration: 500 });
		}
	}

	// On page load select the first 'All services' group
	selectCategoryGroup('parent-more', false);

	// Click on a specific child category
	$('.child-categories .child-category').click(function () {
		var id = $(this).attr('id').replace('category-id-', '');
		if (id) {
			var createUrl = '';

			if (window.Bark.ENV.locale && window.Bark.ENV.lang) {
				createUrl = Bark.sprintf(
					'/%s/%s',
					window.Bark.ENV.lang.toLowerCase(),
					window.Bark.ENV.locale.toLowerCase(),
				);
			}

			createUrl += '/sellers/create-account/?category=' + categoryId;
			location.href = _l(createUrl);
		}
	});

	/**
	 * Handler for when an item is selected from the autocomplete
	 * @returns {boolean} <b>True</b> if a match was made
	 */
	function attemptItemSelectFromAutocomplete() {
		var input = $('#category');
		var sci = input.data('streamcomplete');
		var i;
		var cur;
		var val = input.val();
		var re = new RegExp('^' + val + '$', 'i');

		for (i = 0; i < sci.results.length; i++) {
			cur = sci.results[i];
			if ((cur.label || '').match(re)) {
				setCategory(cur);
				$('.streamcomplete-body').hide();
				submitSellerSignUpForm();
				return;
			}
		}
		tracking('organic-seller-signup', 'Enter service - fail', { search_param: val });
		$('#category-error').removeClass('d-none').addClass('d-block');
		$('#category').addClass('is-invalid');
	}

	function setCategory(item) {
		if (!item) {
			return;
		}
		categoryId = item.id;
		categoryName = item.name;
		$('.for-category').slideUp({ duration: 200 });
	}

	function submitSellerSignUpForm() {
		$('.invalid-feedback').addClass('d-none').removeClass('d-block');
		$('.is-invalid').removeClass('is-invalid');
		// When they moved away from the category field without selecting
		// but the value matches the category lets select that
		if (!categoryId) {
			attemptItemSelectFromAutocomplete();
		}

		var category = $(' #category').val();
		if (!categoryId || category == '') {
			$('.for-category').slideDown({ duration: 200 });
		} else {
			var createUrl = '';

			if (window.Bark.ENV.locale && window.Bark.ENV.lang) {
				createUrl = Bark.sprintf(
					'/%s/%s',
					window.Bark.ENV.lang.toLowerCase(),
					window.Bark.ENV.locale.toLowerCase(),
				);
			}
			tracking('organic-seller-signup', 'Enter service - success', {
				category_id_hash: categoryId,
				category_name: categoryName,
			});

			createUrl += '/sellers/create-account/?category=' + categoryId;

			Bark.showLoading(400, 'swing', "Let's create your account");
			document.location = _l(createUrl);
		}
	}
}
