import { _t } from '@bark/bark-core';

export function AbandonedBarkJs() {
	var submitting = false,
		locationField = $('#ps-location');

	$('#ps-name')
		.off('change.removeerr')
		.on('change.removeerr', function () {
			$('#ps-name-error').hide();
			$(this).removeClass('is-invalid');
		});

	$('#ps-tel')
		.off('change.removeerr')
		.on('change.removeerr', function () {
			$('#ps-tel-error').hide();
			$(this).removeClass('is-invalid');
		});

	// Initialise the location field, if present
	if (locationField.length) {
		if (typeof Bark.ENV !== 'undefined' && Bark.ENV.locale.toLowerCase() == 'ie') {
			var locationAutocomplete,
				options = {
					componentRestrictions: { country: 'ie' },
				};

			locationAutocomplete = new google.maps.places.Autocomplete(
				locationField.get(0),
				options,
			);
			locationAutocomplete.addListener('place_changed', function () {
				var place = locationAutocomplete.getPlace();
				$('#postcode_id').val(place.place_id);
				$('#postcode_type').val('goog');
			});
		} else {
			locationField.streamComplete({
				src: Bark.sprintf('%s/location/', Bark.ENV.api_hostname),
				requestmethod: 'post',
				delay: 10,
				usestandardsyntax: true,
				onclose: function () {
					$(this).removeClass('ajax-loading');
					$(this).removeClass('results-list-showing');
				},
				preprocessresults: function (data) {
					if (data.length > 10) {
						data = data.slice(0, 10);
					}

					for (var i = 0; i < data.length; i++) {
						var x = data[i];
						x.label = x.name;
					}

					return data;
				},
				onopen: function (data) {
					if (data.length) {
						$(this).addClass('results-list-showing');
					}
				},
				onresponse: function (responses) {
					$(this).removeClass('ajax-loading');
					if (responses.data.length === 1) {
						var item = responses.data[0];

						$('#postcode_id').val(item.id);
						$('#postcode_type').val(item.type);
					} else {
						$('#postcode_id').val(null);
						$('#postcode_type').val(null);
					}
				},
				onselect: function (item) {
					$('#postcode_id').val(item.id);
					$('#postcode_type').val(item.type);
					$('.invalid-feedback').hide();
					$('.is-invalid').removeClass('is-invalid');
				},
				onbeforesearch: function (payload) {
					payload.coid = Bark.ENV.ccid;
				},
				onbeforeajaxsearch: function () {
					var sc = $(this).data('streamcomplete');
					sc.results = [];
					$(this).addClass('ajax-loading');
				},
				onfocusout: function () {
					if (!$(this).val()) {
						$('#postcode_id').val(null);
						$('#postcode_type').val(null);
					}
				},
			});
		}
	}

	$('.submit-in-full')
		.off('click.submit')
		.on('click.submit', function (e) {
			var msg = null,
				hasErrors = false;

			e.preventDefault();

			if (submitting) {
				return false;
			}
			submitting = true;

			// Hide any validation errors
			$('.invalid-feedback').hide();
			$('.is-invalid').removeClass('is-invalid');

			if ($('#ps-name').length) {
				var nameerrors = Bark.createBark.findAccountNameErrors($('#ps-name').val());
				if (nameerrors.empty) {
					msg = _t('public_abandoned-bark:errors.account-name.empty');
				} else if (nameerrors.tooshort) {
					msg = _t('public_abandoned-bark:errors.account-name.too-short');
				} else if (nameerrors.isemail) {
					msg = _t('public_abandoned-bark:errors.account-name.is-email');
				} else if (nameerrors.hasnumbers) {
					msg = _t('public_abandoned-bark:errors.account-name.has-numbers');
				}
				if (msg) {
					hasErrors = true;
					logEvent('invalid value', 'name');
					$('#ps-name-error').text(msg).show();
					$('#ps-name').addClass('is-invalid');
					Bark.addErrorAnimation($('#ps-name'));
				}
			}

			if ($('#ps-tel').length) {
				var telerrors = Bark.createBark.findAccountTelErrors($('#ps-tel').val());

				msg = null;

				if (telerrors.empty) {
					msg = _t('public_abandoned-bark:errors.telephone.empty');
				} else if (telerrors.invalid) {
					msg = _t('public_abandoned-bark:errors.telephone.invalid');
				} else if (telerrors.badchars) {
					msg = _t('public_abandoned-bark:errors.telephone.bad-characters');
				}

				if (msg) {
					hasErrors = true;
					logEvent('invalid value', 'telephone');
					$('#ps-tel-error').text(msg).show();
					$('#ps-tel').addClass('is-invalid');
					Bark.addErrorAnimation($('#ps-tel'));
				}
			}

			if ($('#ps-location').length) {
				var postcodeid = $('#postcode_id').val().trim();
				if (!postcodeid) {
					hasErrors = true;
					logEvent('invalid value', 'location');
					$('#ps-location').addClass('is-invalid');
					$('#ps-location-error')
						.text(
							_t('public_abandoned-bark:errors.location.not-selected', {
								postcode_label: window.postcodeLabel,
							}),
						)
						.show();
					Bark.addErrorAnimation($('#ps-location'));
				}
			}

			if (hasErrors) {
				submitting = false;
				return false;
			}

			Bark.showLoading(_t('public_abandoned-bark:submit-loading-text'));

			if ($('#ps-tel').length) {
				// Submit if the phone number is valid
				validateTelAndSubmit($('#ps-tel').val());
			} else {
				// Just submit
				doSubmit();
			}
			return false;
		});

	/**
	 * Logs an event
	 * @param eventCategory
	 * @param eventName
	 * @param eventId
	 */
	function logEvent(eventName, eventId, eventCategory) {
		$.post('/api/event-log/', {
			event_category: eventCategory || 'abandoned_bark',
			event_name: eventName || '',
			event_id: eventId || '',
		});
	}

	/**
	 * Validate the telephone number for the user
	 * @param {string} tel The telephone number to validate
	 */
	function validateTelAndSubmit(tel) {
		Bark.validate.ukTel(
			tel,
			function (success, moderate) {
				if (success || (!success && moderate)) {
					// The phone is okay-ish
					doSubmit();
				} else {
					logEvent('invalid value', 'telephone-external');
					$('#ps-tel').addClass('is-invalid');
					$('#ps-tel-error')
						.text(_t('public_abandoned-bark:errors.telephone.invalid'))
						.show();
					submitting = false;
					Bark.hideLoading();
				}
			},
			false,
			'buyer',
		);
	}

	/**
	 * Perform the actual submit of the form
	 */
	function doSubmit() {
		const clone = $.extend(true, {}, window.answers);
        const projectData = clone?.meta?.project_data || {};
		delete clone.jsclientid;
        delete clone?.meta?.project_data;

		clone.abandonedbarkid = window.abandonedbarkid;
        clone.project_data = projectData;

        if (document.getElementsByName('nrfTrustedFormToken')[0]) {
            const tformtoken = document.getElementsByName('nrfTrustedFormToken')[0];
            clone.tform_token = String(tformtoken.getAttribute('value'));
        }

		// Check to see if the poorly-named is_local checkbox was present and checked. The label for that
		// checkbox actually states that the Bark can be nationwide/remote. Not all categories show the checkbox
		// * Present and checked = nationwide
		// * Present and unchecked = local
		// * Not present = local
		// For the payload sent to the API, is_local actually means local, not nationwide
		if (clone.hasOwnProperty('is_local') && !!clone.is_local) {
			clone.is_local = false;
		} else {
			clone.is_local = true;
		}

		$('.for-override').each(function () {
			var t = $(this);
			var name = t.data('name');
			clone[name] = t.val();
		});

		Bark.createBark.toggleAdditionalDetails(false);
		Bark.createBark.toggleSubmittingAbandonedBark(true);
		Bark.createBark.postBarkToApi(100, clone);
	}
}
