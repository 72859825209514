import { tracking as barkTrackingLogEvent } from '@bark/bark-core';
const page = 'seller-list';
const response_page = 'response-list';

export const handleBookNow = (
	seller_id,
	company_name,
	buyer_id,
	projectHashId,
	seller_profile_hash_id,
	page,
	categoryId,
	marketplaceInteractionCompleteCallback,
	isLastSellerToInteractOnMarketplaceList,
) => {
	const projectId = $('#_project-id').val();

	try {
		showBookNowModal(
			seller_id,
			company_name,
			buyer_id,
			projectId,
			seller_profile_hash_id,
			page,
			categoryId,
			projectHashId,
			marketplaceInteractionCompleteCallback,
			isLastSellerToInteractOnMarketplaceList,
		);
	} catch (error) {
		console.log(error);
	}
};

export const enquiryStartWaiting = ($button) => {
	$button.find('.btn-icon-text').removeClass('d-flex').addClass('d-none');
	$button.find('.spinner-border').removeClass('d-none').addClass('d-flex');
};

export const enquiryStopWaiting = ($button) => {
	$button.find('.btn-icon-text').removeClass('d-none').addClass('d-flex');
	$button.find('.spinner-border').removeClass('d-flex').addClass('d-none');
};

export const showBookNowModal = (
	seller_Id,
	company_name,
	buyer_id,
	project_id,
	seller_profile_hash_id,
	page,
	categoryId,
	projectHashId,
	marketplaceInteractionCompleteCallback,
	isLastSellerToInteractOnMarketplaceList,
) => {
	if (window.BuyerCallbacks) {
		window.BuyerCallbacks.launchModule('callbacks-modal', 'callbacks-modal', {
			agent: { name: company_name, timezone: 'UTC' },
			trackingsArgs: {
				project_id: project_id,
				seller_profile_hash_id: seller_profile_hash_id,
				page: page,
				pre_bark_id: project_id,
				buyer_user_id: buyer_id,
				category_id: categoryId,
				project_hash_id: projectHashId,
			},
			seller_profile_id: seller_Id,
			showBookingConfirmation: !isLastSellerToInteractOnMarketplaceList,
			onComplete: (meta) =>
				marketplaceInteractionCompleteCallback?.(
					'marketplace-booking',
					seller_profile_hash_id,
					meta,
				),
		});
	}
};

export const handleBookingCancellation = ({
	booking_id,
	seller_id,
	company_name,
	buyer_id,
	project_id,
	seller_profile_hash_id,
	response_id,
	category_id,
}) => {
	try {
		showBookingCancellationModal(
			booking_id,
			seller_id,
			company_name,
			buyer_id,
			project_id,
			seller_profile_hash_id,
			response_page,
			response_id,
			category_id,
		);
	} catch (error) {
		console.log(error);
	}
};

export const showBookingCancellationModal = (
	booking_id,
	seller_id,
	company_name,
	buyer_id,
	project_id,
	seller_profile_hash_id,
	page,
	response_id,
	category_id,
) => {
	if (window.BuyerCallbacks) {
		window.BuyerCallbacks.launchModule(
			'callbacks-cancellation-modal',
			'callbacks-modal',
			{
				trackingsArgs: {
					seller_id,
					seller_profile_hash_id,
					company_name,
					page,
					pre_bark_id: project_id,
					project_id: project_id,
					buyer_user_id: buyer_id,
					category_id,
					response_id,
				},
				bookingId: booking_id,
			},
		);
	}
};

export const handleBookNowError = ($button) => {
	const sellerId = $button.data('sellerId');
	$button.prop('disabled', true);
	$(`.enquiries-feedback[data-seller-id=${sellerId}]`).removeClass('d-none');
};
