export default function({ providerNoun }) {
    const defaultDelay = 2000;
    let state = {
        providerNoun,
        finishAnimationOverride: false
    }

    const $sellerList = $('#project-seller-list');

    const loadingStates = [{
            'name': 'searching',
            'text': 'Searching our database...',
            'showFor': defaultDelay
        },
        {
            'name': 'contacting',
            'text': `Finding ${providerNoun}...`,
            'showFor': defaultDelay
        },
        {
            'name': 'preparing',
            'text': 'Preparing list...',
            'showFor': defaultDelay
        }
    ];

    const startAnimation = () => {
        toggleVisibility($('.js-default-text'), false)
        toggleVisibility($('.animation-content-wrapper'), true);
        animate();
    }

    const animate = (currentStep) => {
        $('.js-seller-list-loading-container').removeClass('waiting-hidden');

        currentStep = currentStep ? currentStep : 0;
        let steps = loadingStates.length;

        if (currentStep >= steps || state.finishAnimationOverride) {
            finishAnimation();
            return;
        }

        let { text, showFor } = loadingStates[currentStep];

        fadeText($('.js-animated-text p'), text);
        let nextStep = currentStep + 1;

        setTimeout(() => {
            animate(nextStep);
        }, showFor)
    }

    const hideLoadingAnimation = () => {
        $('.js-loading-image').slideUp(400);
    }

    const toggleVisibility = ($selector, show) => {
        if (show) {
            $selector.removeClass('d-none');
        } else {
            $selector.addClass('d-none');
        }
    }

    const setProviderNoun = (providerNoun) => {
        state.providerNoun = providerNoun;
        $('.js-category-name').text(providerNoun);
    }

    const setMorePros = (sellerCount) => {
        if (sellerCount > 0) {
            $('.js-more-pros').text('more');
        }
    }

    const fadeText = (element, text) => {
        element.fadeOut(function() {
            $(this).text(text)
        }).fadeIn();
    }

    const renderWithNoAnimation = () => {
        toggleVisibility($('.animation-content-wrapper'), false);
        toggleVisibility($('.js-default-text'), true);
    }

    const finishAnimation = () => {
        $sellerList.trigger('searchingForSellersAnimationLastStep');
    }

    const finishAnimationAndGoToLastStep = () => {
        $sellerList.trigger('searchingForSellersAnimationOverride');
    }

    const bindListeners = () => {
        let sellersLoadedPromise = new Promise(function(resolve) {
            $sellerList.on('initialSellersLoaded', () => {
                resolve()
            });
        });
        let animationFinishedPromise = new Promise(function(resolve) {
            $sellerList.on('searchingForSellersAnimationLastStep', () => {
                resolve()
            });
        });
        let animationOverridePromise = new Promise(function(resolve) {
            $sellerList.on('searchingForSellersAnimationOverride', () => {
                resolve()
            });
        });

        $sellerList.on('allSellersLoaded', () => {
            const $animatedText = $('.js-animated-text p')
            if ($animatedText.text() === 'Preparing list...') {
                fadeText($animatedText, `We are still searching for ${state.providerNoun}...`);
            }
        });

        Promise.race([
                Promise.all([sellersLoadedPromise, animationFinishedPromise]),
                animationOverridePromise
            ])
            .then(() => {
                hideLoadingAnimation();
                $sellerList.trigger('searchingForSellersAnimationFinished');
            })
            .catch(err => {
                bugsnagClient && bugsnagClient.notify(err);
                hideLoadingAnimation();
                $sellerList.trigger('searchingForSellersAnimationFinished');
            });
    }

    bindListeners();

    return {
        startAnimation,
        renderWithNoAnimation,
        setProviderNoun,
        setMorePros,
        finishAnimationAndGoToLastStep,
    }
}
